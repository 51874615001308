<template>
  <div>

    <b-modal v-model="modalShow" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header="true" :hide-footer="true" size="xl" scrollable>
      <p class="title">合格投资者认定</p>
      <div class="content">
        <p>在继续浏览本公司网站前，请您仔细阅读本重要提示，并将页面滚动至本页结尾根据您的具体情况选择继续浏览还是放弃。</p>

        <p>请您确认您或您所代表的机构是一名“合格投资者”。“合格投资者”指根据任何国家和地区的证券和投资法规所规定的有资格投资于私募证券投资基金的专业投资者。我国私募基金的合格投资者应符合《私募投资基金监督管理暂行办法》的规定，具备相应风险识别能力和风险承担能力。私募基金合格投资者的标准如下：</p>

        <p>一、具备相应风险识别能力和风险承担能力，投资于单只私募基金的金额不低于100万元且符合下列相关标准的单位和个人：</p>
        <p>1、净资产不低于1000万元的单位；
        <p>2、个人金融资产不低于300万元或者最近三年个人年均收入不低于50万元的个人。</p>

        <p>前款所称金融资产包括银行存款、股票、债券、基金份额、资产管理计划、银行理财产品、信托计划、保险产品、期货权益等。</p>

        <p>二、下列投资者视为合格投资者：</p>
        <p>1、社会保障基金、企业年金、慈善基金；</p>
        <p>2、依法设立并受国务院金融监督管理机构监管的投资计划；</p>
        <p>3、投资于所管理私募基金的私募基金管理人及其从业人员；</p>
        <p>4、中国证监会规定的其他投资者。</p>

        <p>如果确认您或您所代表的机构是一名“合格投资者”，并将遵守适用的有关法律法规请点击“确认”键以继续浏览本公司网站。如您不确认任何有关条款，请按“放弃”键。</p>

        <p>本网站所载的各种信息和数据等仅供参考，本网站所载的观点和判断仅代表我们的分析，并不构成广告或销售邀约，以及任何投资建议或实际的投资结果。也不保证当中的观点和判断不会发生任何调整或变更。投资者应仔细审阅相关金融产品的合同文件等以了解其风险因素，或寻求专业的投资顾问的建议。</p>

        <p>未经本公司官方书面许可，任何人或第三方不得转载或发布本网站内的任何信息及资料。 </p>

        <p>本公司对本网站的安全性、连续性及准确性不承担任何形式的保证。如您同意浏览本网站，则代表您愿意承担使用本网站服务的所有风险及可能导致的损失。本公司不对因技术故障或其他原因而引起的数据损失承担任何责任。</p>

        <p>进入、访问与使用本网站，以及本申明条款受中华人民共和国法律管辖，并依照中华人民共和国法律解释。</p>
      </div>
      <div class="op">
        <b-button variant="outline-primary accept" @click="accept">接受</b-button>
        <b-button variant="outline-primary" @click="giveUp">放弃</b-button>
      </div>
      <div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data () {
    return {
      modalShow: false
    }
  },
  methods: {
    showModal: function () {
      this.modalShow = true
    },
    hideModal: function () {
      this.modalShow = false
    },
    accept: function () {
      localStorage.agree = 'true'
      this.hideModal()
    },
    giveUp: function () {
      this.hideModal()
      window.location.href = 'about:blank'
    }
  },
  mounted: function () {
    if (localStorage.agree === 'true') {
      this.hideModal()
    } else {
      this.showModal()
    }
  }
}
</script>
<style scoped>
.title {
  font-size: 24px;
  text-align: center;
}
.accept {
  margin-right: 2rem;
}
.op {
  text-align: center;
}
.op button {
    width: 240px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background-color: #4773b9;
    color: #fff;
    display: inline-block;
    border-radius: 2px;
    font-size: 14px;
    padding: 0;
}
.content{
  height: 65vh;
  overflow: auto;
  margin-bottom: 2rem;
  border:solid 1px #979797;
  padding: 15px;
  background-color: #f1f1f1;
}
 .content::-webkit-scrollbar {
            width: 4px;
        }
        .content::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: rgba(0,0,0,0.2);
        }
        .content::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            background: rgba(0,0,0,0.1);

        }
</style>
