<template>
  <div>
    <div class="main" v-title data-title="百川资管-首页">
    </div>
    <Header class="header" active="index"/>
    <div class="banner">
      <div class="background">
        <b-container>
          <div class="title">专注基本面量化投资的基金公司</div>
          <div class="text">
            <div class="sub-title">* 跨境中国资产的配置力</div>
            <div class="sub-title">* 基本面+量化双轮驱动</div>
            <div class="sub-title">* 基于人工智能投研系统</div>
            <div></div>
            <div></div>
          </div>
        </b-container>
      </div>
    </div>
    <div class="content">
      <b-container class="statement">
        <div class="icon-wrap">
          <b-icon-volume-up-fill></b-icon-volume-up-fill>
        </div>
        <div class="title" @click="routerToDetail">百川财富（北京）投资管理有限公司严正声明</div>
      </b-container>
      <section class="index">
        <b-container>
          <div class="left">
            <div class="product">
              <div class="text">
                <div class="title">基金产品</div>
                <div class="sub-title">为机构及高净值客户，提供专业、可信赖的资产管理服务</div>
                <a href="/product">了解产品</a>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="ai">
              <div class="text">
                <div class="title">AI投研</div>
                <div class="sub-title">投研系统化</div>
                <div class="sub-title">AI解读财务报表</div>
                <div class="sub-title">宏观、行业的配置模型</div>
                <div class="sub-title">基本面及市场信息的及时反馈</div>
                <a href="/ai">走进AI</a>
              </div>
            </div>
            <div class="talent">
              <div class="text">
                <div class="title">汇聚英才</div>
                <div class="sub-title">海纳百川，汇聚英才</div>
                <div class="sub-title">欢迎有共同愿景的业界精英，共聚百川。</div>
                <a href="/join">加入百川</a>
              </div>
            </div>
          </div>
        </b-container>
      </section>
    </div>
    <Footer class="footer" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Index',
  components: {
    Header,
    Footer
  },
  methods: {
    routerToDetail () {
      console.log(this.$router)
      this.$router.push({ name: 'statementDetail' })
    }
  }
}
</script>

<style scoped>
.banner {
  height: 550px;
}
.banner .background {
  width: 100%;
  height: 100%;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/index1.jpg");
}
.banner .title {
  font-size: 32px;
  color: #ffffff;
  line-height: 3;
}
.banner .text {
  position: relative;
  top: 30px;
}
.banner .sub-title {
  font-size: 22px;
  color: #ffffff;
  line-height: 2;
}
.banner .container {
  position: relative;
  top: 200px;
}
.left {
  display: inline-block;
  vertical-align: top;
}
.left .product {
  width: 382.5px;
  height: 594px;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/index2.png");
}
.right {
  display: inline-block;
  vertical-align: top;
  margin-left: 18px;
}
.right .ai {
  width: 571.5px;
  height: 288px;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/index3.png");
}
.right .talent {
  margin-top: 18px;
  width: 571.5px;
  height: 288px;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/index4.png");
}
.index {
  padding: 20px 0 40px;
}
.index .title {
  font-size: 28px;
  line-height: 3;
}
.index .sub-title {
  font-size: 16px;
}
.index .left .text {
  padding: 50px 40px;
}
.index a {
  border: solid 1px #ffffff;
  color: #ffffff;
  position: relative;
  top: 20px;
  padding: 6px 25px;
  border-radius: 3px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.2);
  text-decoration: none;
}
.index .right .text {
  padding: 20px 40px;
}
.statement {
  height: 54px;
  line-height: 34px;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  padding-top: 20px !important;
  cursor: pointer;
}
.statement .icon-wrap {
  color: red;
}
.statement .title {
  padding-left: 10px;
}
</style>
