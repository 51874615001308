<template>
  <div>
    <b-container>
      <b-row>
        <div class="title">
          联系百川
        </div>
      </b-row>
      <b-row>
        <div class="col-8 offset-md-1">
          <div class="sub-title">
            电话：0755-23482434
          </div>
          <div  class="sub-title">邮箱：bczg@baichuanasset.com</div>
          <div class="sub-title">北京办公室地址：北京市海淀区温泉镇温泉路84号临10号1257</div>
          <div class="sub-title">深圳办公室地址：深圳市福田区福华一路98号卓越大厦306</div>
          <p>
            <a  href="https://beian.miit.gov.cn" target="_blank" style="text-decoration:underline;color:#ffffff">京ICP备2021004903号-1</a>
          </p>
        </div>
        <div class="col-3">
          <div>
            <div class="sub-title qr-desc">微信扫码关注</div>
            <img src="../assets/qrcode.png" class="qrcode">
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.footer {
  color: #ffffff;
  background-color: #18466e;
  padding: 30px 0 50px 0;
}
.footer .container {
  width: 992px;
}
.footer .title{
  text-align: center;
  font-size: 28px;
  width: 100%;
  padding-bottom: 30px;
}
.footer .sub-title{
  line-height: 2;
}
.qr-desc{
  width: 132px;
  text-align: center;
  padding-bottom: 10px;
}
.qrcode{
  width: 129px;
}
</style>
