<template>
  <div>
    <b-navbar>
      <b-container>
        <b-navbar-brand href="#"><img src="../assets/logo.png" class="logo"></b-navbar-brand>
        <b-navbar-nav class="ml-auto">
          <b-nav-item :active="active=='index'" href="/">首页</b-nav-item>
          <!-- <b-nav-item :active="active=='product'" href="/product">基金产品</b-nav-item> -->
          <b-nav-item :active="active=='about'" href="/about">我们是谁</b-nav-item>
          <b-nav-item :active="active=='website'" @click="jumpDetail">产品中心</b-nav-item>
          <b-nav-item :active="active=='ai'" href="/ai">AI投研</b-nav-item>
          <b-nav-item :active="active=='join'" href="/join">加入百川</b-nav-item>
          <b-nav-item :active="active=='contact'" href="/contact">联系我们</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto sub-nav">
          <b-nav-item @click="jumpLogin">登录/注册</b-nav-item>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
    <Modal></Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  components: {
    Modal
  },
  data () {
    return {
    }
  },
  props: {
    active: {
      type: String,
      required: true
    }
  },
  methods: {
    jumpDetail () {
      window.open('https://vipfunds.simu800.com/vipmobile/product?companyCode=87F20E006727DF8F1A0F6132430C8B42')
      // window.location.href = 'https://vipfunds.simu800.com/vipmobile/product?companyCode=87F20E006727DF8F1A0F6132430C8B42'
      // window.location.href = 'http://dl.baichuanasset.com/website/w/h?mt=8&mc=47228517&cc=46258547'
    },
    jumpLogin () {
      window.open('https://vipfunds.simu800.com/vipmobile/login?companyCode=87F20E006727DF8F1A0F6132430C8B42')
      // window.location.href = 'https://vipfunds.simu800.com/vipmobile/login?companyCode=87F20E006727DF8F1A0F6132430C8B42'
      // window.location.href = 'http://dl.baichuanasset.com/website/w/h?mt=6&mc=-999&cc=46258547'
    }
  }
}
</script>
<style scoped>
.header {
  position: absolute;
  width: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.8);
}
.header .logo {
  height: 50px;
}
.header .nav-item a {
  color: #333333;
}
.navbar-nav .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.navbar-nav .nav-item {
  padding: 0 1.2rem;
}
.header .container {
  width: 992px;
}
.navbar-nav .nav-link {
  padding: 3px 0;
  border-bottom: solid 2px rgba(255, 255, 255, 0);
}
.navbar-nav .nav-link:hover {
  border-bottom: solid 2px #333333;
}
.navbar-nav .active {
  border-bottom: solid 2px #333333;
}
.sub-nav {
  height: 32px;
}
</style>
